import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Styles from "./css/ripple.module.scss"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import Btf_carbonate from "src/components/btf_carbonate"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Carbonate_disc_search from "src/components/carbonate_disc_search"


const wavelpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default class Ripple extends React.Component {
  state = {
    data: {}
  };

  componentDidMount() {
    wavelpData.then(result => {
      this.setState({ data: result });
    });
  }

  render() {
    return(
      <HomepageLayout>
      <Helmet>
       <style type="text/css">{`

body {
	font-family: 'Roboto', sans-serif;
}
       .footerlinks-module--br {
         display: none!important;
       }

       .wave-module--wave ul.wave-module--bullets {
         padding: 16px 0 16px 40px;
       }

       .wave-module--wave button.wave-module--waveBtn{
       	animation: 1.5s normal 0.5s 1;
        animation-name: slideInFromLeft;
       	animation-fill-mode: forwards;

       }
       @keyframes slideInFromLeft {
       	from {
       		background-position: right bottom;
       	}
       	to {
       		background-position: 0 0;
       		background-color: #096bfb;
       	}
       }

       #ctadisclosurecb3-module--ctadisclaimer {
         display: none;
       }

       .wave-module--wave h1 {
         font-size: 22px;
       }
    `}
    </style>
    <style type="text/css">
         </style>
    <title>Block Ads and Browse The Internet Faster - Carbonate</title></Helmet>
    <section>
      <header className={Styles.header}>
        <div className={Styles.headerContainer}>
          <div className={Styles.logoContainer}>
            <img src="/img/carbonate-logo-ripple.svg" alt="Carbonate Logo" />

          </div>
        </div>
      </header>
      <div className={Styles.content}>
      <div className={Styles.ripple}>
        <h1>Carbonate Browser</h1>
        <h2>Block Ads and Browse The Internet Faster</h2>

        <div className={Styles.features}>
          <div className={Styles.featureItem}>
            <img src="/img/fast-load.svg" alt="Fast Load Times" />
            <p>Fast Load Times</p>
          </div>
          <div className={Styles.featureItem}>
            <img src="/img/ad-block.svg" alt="Ad-blocker Enabled" />
            <p>Ad-blocker Enabled</p>
          </div>
          <div className={Styles.featureItem}>
            <img src="/img/navigate.svg" alt="Easy to Navigate" />
            <p>Easy to Navigate</p>
          </div>
          <div className={Styles.featureItem}>
            <img src="/img/security-mal.svg" alt="Secure from Malware" />
            <p>Secure from Malware</p>
          </div>
        </div>
        <button className={[Styles.startButton, Styles.cl1].join(' ')} data-cy="cta" onClick={() => window.triggerInstall()}>
          <span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span>
        </button>

        <Carbonate_disc_search></Carbonate_disc_search>

        <div className={Styles.infoLinks}>
          <AnchorLink href="#what-is-carbonate" className={Styles.infoItem}>
            <img src="/img/what-is.svg" alt="What is Carbonate?" />
            <span>What is Carbonate?</span>
          </AnchorLink>
          <AnchorLink href="#features" className={Styles.infoItem}>
            <img src="/img/features.svg" alt="Features" />
            <span>Features</span>
          </AnchorLink>
          <AnchorLink href="#features" className={Styles.infoItem}>
            <img src="/img/learn-more.svg" alt="Learn More" />
            <span>Learn More</span>
          </AnchorLink>
          </div>
        </div>
      </div>
      <div id="what-is-carbonate" className={Styles.belowFold}>
        {/* Below fold content */}
      </div>
      <Btf_carbonate data={btfData}></Btf_carbonate>
    </section>
    </HomepageLayout>
    )
  }
}
